import { createRouter, createWebHashHistory } from 'vue-router';
import routes from './routes';
import { localStore } from '@/utils/store';

const router = createRouter({
  history: createWebHashHistory('/'),
  routes,
});

router.beforeEach((to, _, next) => {
  if (to.meta.noVerify || to.meta.noOverdue) {
    next();
    return;
  }
  const token = localStore.get('token');
  if (to.path.startsWith('/user/login')) {
    next();
    return;
  }
  if (!token) {
    next(`/user/login`);
  }
  next();
});

export default router;
