import 'vue-global-api';
import { createPinia } from 'pinia';
import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import './antd.less';
import './global.scss';
import VueQuillTextEditor from 'vue-quill-text-editor';
import 'vue-quill-text-editor/dist/vue-quill-text-editor.esm.css';
import 'viewerjs/dist/viewer.css';
import copy from '@/directives/copy';
import drag from '@/directives/drag';

const app = createApp(App);

app.use(createPinia()).use(router).use(VueQuillTextEditor).mount('#app');
app.directive('copy', copy);
app.directive('drag', drag);
