import loadable from '@/utils/loadable';
import * as ConstantRouteName from '@/constants/route-name';

const route = {
  path: 'user-center',
  name: ConstantRouteName.USER_CENTER,
  redirect: {
    name: ConstantRouteName.USER_LIST,
  },
  children: [
    {
      path: '',
      name: ConstantRouteName.USER_LIST,
      component: loadable(
        () => import(/* webpackChunkName: "table-list" */ '@/pages/user-center/index')
      ),
    },
    {
      path: 'detail/:userId',
      name: ConstantRouteName.USER_DETAIL,
      component: loadable(
        () => import(/* webpackChunkName: "account" */ '@/pages/user-center/detail/index'),
      ),
    },
    {
      path: 'pay-watch/:userId',
      name: ConstantRouteName.USER_PAY_WATCH,
      component: loadable(
        () => import(/* webpackChunkName: "account" */ '@/pages/user-center/user-pay-watch/index'),
      ),
    },
    {
      path: 'red-package/:userId',
      name: ConstantRouteName.USER_RED_PACKAGE,
      component: loadable(
        () => import(/* webpackChunkName: "account" */ '@/pages/user-center/user-red-package/index'),
      ),
    },
    {
      path: 'goods-pay/:userId',
      name: ConstantRouteName.USER_GOODS_PAY,
      component: loadable(
        () => import(/* webpackChunkName: "account" */ '@/pages/user-center/user-goods-pay/index'),
      ),
    },
    {
      path: 'bandwidth/:userId',
      name: ConstantRouteName.USER_BANDWIDTH,
      component: loadable(
        () => import(/* webpackChunkName: "account" */ '@/pages/user-center/user-bandwidth/index'),
      ),
    },
  ]
};

export default route;
