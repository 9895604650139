export const USER_CENTER = '用户管理';
export const USER_LIST = '用户列表';
export const USER_DETAIL = '用户详情';
export const USER_PAY_WATCH = '付费观看流水';
export const USER_RED_PACKAGE = '红包账户流水';
export const USER_BANDWIDTH = '流量使用明细';

export const ANCHOR_WITHDRAW = '主播提现';
export const ANCHOR_WITHDRAW_LIST = '主播提现';
export const ANCHOR_WITHDRAW_DETAIL = '提现详情';

export const AUDIENCE_WITHDRAW = '观众提现';
export const AUDIENCE_WITHDRAW_LIST = '观众提现';
export const AUDIENCE_WITHDRAW_DETAIL = '提现详情';

export const USER_REVIEW = '实名审核';