export const USER = 'user';
export const LOGIN = 'login';

export const USER_CENTER = 'user-center';
export const USER_LIST = 'user-list';
export const USER_DETAIL = 'user-detail';
export const USER_PAY_WATCH = 'user-pay-watch';
export const USER_RED_PACKAGE = 'user-red-package';
export const USER_GOODS_PAY = 'user-goods-pay';
export const USER_BANDWIDTH = 'user-bandwidth';


export const ANCHOR_WITHDRAW = 'anchor-withdraw';
export const ANCHOR_WITHDRAW_LIST = 'anchor-withdraw-list';
export const ANCHOR_WITHDRAW_DETAIL = 'anchor-withdraw-detail';

export const AUDIENCE_WITHDRAW = 'audience-withdraw';
export const AUDIENCE_WITHDRAW_LIST = 'audience-withdraw-list';
export const AUDIENCE_WITHDRAW_DETAIL = 'audience-withdraw-detail';

export const USER_REVIEW = 'user-review';