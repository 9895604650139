import loadable from '@/utils/loadable';
import * as ConstantRouteName from '@/constants/route-name';

const route = {
  path: 'user-review',
  name: ConstantRouteName.USER_REVIEW,
  component: loadable(
    () => import(/* webpackChunkName: "table-list" */ '@/pages/user-review/index')
  ),
};

export default route;
