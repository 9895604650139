import loadable from '@/utils/loadable';
import * as ConstantRouteName from '@/constants/route-name';

const route = {
  path: 'anchor-withdraw',
  name: ConstantRouteName.ANCHOR_WITHDRAW,
  redirect: {
    name: ConstantRouteName.ANCHOR_WITHDRAW_LIST,
  },
  children: [
    {
      path: '',
      name: ConstantRouteName.ANCHOR_WITHDRAW_LIST,
      component: loadable(
        () => import(/* webpackChunkName: "table-list" */ '@/pages/anchor-withdraw/index')
      ),
    },
    {
      path: 'detail/:id',
      name: ConstantRouteName.ANCHOR_WITHDRAW_DETAIL,
      component: loadable(
        () => import(/* webpackChunkName: "account" */ '@/pages/anchor-withdraw/detail/index'),
      ),
    },
  ]
};

export default route;
