export function treeToMap(tree, key = 'key') {
  const fn = (tree) => {
    return tree.reduce((pre, next) => {
      return {
        ...pre,
        [next[key]]: next,
        ...(next.children && next.children.length ? fn(next.children) : null),
      };
    }, {});
  };
  return fn(tree);
}

export function secondToDate(result, { s } = { s: true }) {
  const hours = Math.floor(result / 3600);
  const minutes = Math.floor((result / 60) % 60);
  const seconds = Math.floor(result % 60);
  const dateStr =
    (hours < 10 ? '0' + hours : hours) +
    ':' +
    (minutes < 10 ? '0' + minutes : minutes) +
    (s ? ':' + (seconds < 10 ? '0' + seconds : seconds) : '');

  return dateStr;
}

export function getStartTimestamp(time) {
  return new Date(time).setHours(0, 0, 0, 0);
}

export function getEndTimestamp(time) {
  return new Date(time).setHours(23, 59, 59, 999);
}

export function formatPercent(val = 0) {
  return (val / 100).toFixed(2);
}