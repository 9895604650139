import * as ConstantRouteName from '@/constants/route-name';
import * as ConstantRouteLabel from '@/constants/route-label';

import { UserOutlined } from '@ant-design/icons-vue';
import { treeToMap } from '@/utils/tools';

const menu = [
  {
    title: ConstantRouteLabel.USER_CENTER,
    key: ConstantRouteName.USER_CENTER,
    icon: UserOutlined,
    hideChildrenInMenu: true,
    children: [
      { title: ConstantRouteLabel.USER_LIST, key: ConstantRouteName.USER_LIST },
      { title: ConstantRouteLabel.USER_DETAIL, key: ConstantRouteName.USER_DETAIL },
      { title: ConstantRouteLabel.USER_PAY_WATCH, key: ConstantRouteName.USER_PAY_WATCH },
      { title: ConstantRouteLabel.USER_RED_PACKAGE, key: ConstantRouteName.USER_RED_PACKAGE },
      { title: ConstantRouteLabel.USER_BANDWIDTH, key: ConstantRouteName.USER_BANDWIDTH },
    ],
  },
  {
    title: ConstantRouteLabel.ANCHOR_WITHDRAW,
    key: ConstantRouteName.ANCHOR_WITHDRAW,
    icon: UserOutlined,
    hideChildrenInMenu: true,
    children: [
      { title: ConstantRouteLabel.ANCHOR_WITHDRAW_LIST, key: ConstantRouteName.ANCHOR_WITHDRAW_LIST },
      { title: ConstantRouteLabel.ANCHOR_WITHDRAW_DETAIL, key: ConstantRouteName.ANCHOR_WITHDRAW_DETAIL },
    ],
  },
  {
    title: ConstantRouteLabel.AUDIENCE_WITHDRAW,
    key: ConstantRouteName.AUDIENCE_WITHDRAW,
    icon: UserOutlined,
    hideChildrenInMenu: true,
    children: [
      { title: ConstantRouteLabel.AUDIENCE_WITHDRAW_LIST, key: ConstantRouteName.AUDIENCE_WITHDRAW_LIST },
      { title: ConstantRouteLabel.AUDIENCE_WITHDRAW_DETAIL, key: ConstantRouteName.AUDIENCE_WITHDRAW_DETAIL },
    ],
  },
  {
    title: ConstantRouteLabel.USER_REVIEW,
    key: ConstantRouteName.USER_REVIEW,
    icon: UserOutlined,
  },
];

export const menuMap = treeToMap(menu);

export default menu;
