import PageLayout from '@/layout/page';
import * as ConstantRouteName from '@/constants/route-name';

import userCenterRoute from './user-center';
import anchorWithdrawRoute from './anchor-withdraw';
import audienceWithdrawRoute from './audience-withdraw';
import userReviewRoute from './user-review';

const route = {
  path: '',
  component: PageLayout,
  redirect: {
    name: ConstantRouteName.USER_CENTER,
  },
  children: [userCenterRoute, anchorWithdrawRoute, audienceWithdrawRoute, userReviewRoute],
};

export default route;
